<template>
	<div v-loading="fullscreenLoading" style="    box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;
    border-radius: 0.7vw;
    margin-top: 33px;
    padding: 11px;
    background: #fff;">
		<!--ที่อยู่จัดส่ง-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 0">
			<div class="block-text">ที่อยู่จัดส่ง</div>

			<div clsas="address-list">
				<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						เพิ่มที่อยู่สําหรับจัดส่ง
					</div>
				</div>

				<div class="address-item" v-for="(item, key) in memberAddress" :key="item.id" :class="addressId == item.id ? 'active' : ''"
				 v-if="key < 3 || (addressShow && key >= 3)">
					<div class="address-info">
						<div class="options">
							<div @click="editAddress(item.id)">แก้ไข</div>
							<template v-if="item.is_default == 0">
								<el-popconfirm title="คุณแน่ใจหรือไม่ว่าคุณต้องการลบที่อยู่？" @onConfirm="deleteMemberAddress(item.id)">
									<div slot="reference">ลบ</div>
								</el-popconfirm>
							</template>
						</div>
						<div class="address-name">{{ item.name }}</div>
						<div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
						<div class="address-desc" @click="setMemberAddress(item.id)">{{ item.full_address }} {{ item.address }}</div>
					</div>
				</div>

				<div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true" class="el-button--text address-open">
					<i class="el-icon-arrow-down"></i>
					ที่อยู่จัดส่งเพิ่มเติม
				</div>

				<div class="clear"></div>
			</div>
		</div>

		<!--ในการซื้อผลิตภัณฑ์เสมือนจริงคุณต้องกรอกหมายเลขโทรศัพท์มือถือของคุณ-->
		<div class="item-block" v-if="orderPaymentData.is_virtual == 1">
			<div class="block-text">ในการซื้อผลิตภัณฑ์เสมือนจริงคุณต้องกรอกหมายเลขโทรศัพท์มือถือของคุณ，เพื่อให้ผู้ขายติดต่อคุณได้ง่ายขึ้น</div>

			<el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
				<el-form-item label="หมายเลขโทรศัพท์มือถือ">
					<el-input placeholder="กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ" maxlength="11" v-model="orderCreateData.member_address.mobile"></el-input>
				</el-form-item>
			</el-form>
		</div>

		<!--เพิ่มที่อยู่จัดส่งแล้ว-->
		<el-dialog :title="addressForm.id == 0 ? 'เพิ่มที่อยู่สําหรับจัดส่ง' : 'แก้ไขที่อยู่สําหรับจัดส่ง'" :visible.sync="dialogVisible" width="32%">
			<el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
				<el-form-item label="ชื่อ" prop="name">
					<el-input v-model="addressForm.name" placeholder="ชื่อของผู้รับสินค้า"></el-input>
				</el-form-item>
		
				<el-form-item label="เบอร์โทร" prop="mobile">
					<el-input v-model="addressForm.mobile" maxlength="11" placeholder="หมายเลขโทรศัพท์มือถือของผู้รับสินค้า"></el-input>
				</el-form-item>
		
				<el-form-item label="เบอรโทร์2">
					<el-input v-model="addressForm.telephone" placeholder="เบอร์โทรศัพท์（เสริม）"></el-input>
				</el-form-item>
		
				<el-form-item class="area" label="สถานที่" prop="area">
					<el-row :gutter="10">
						<el-col :span="7">
							<el-select prop="province" ref="province" v-model="addressForm.province_id" @change="getAddress(1)" placeholder="เลือก">
								<el-option label="เลือก" value="0"></el-option>
								<el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)" placeholder="เลือก">
								<el-option label="เลือก" value="0"></el-option>
								<el-option v-for="item in cityArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="7">
							<el-select ref="district" prop="district" v-model="addressForm.district_id" placeholder="เลือก">
								<el-option label="เลือก" value="0"></el-option>
								<el-option v-for="item in districtArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
					</el-row>
				</el-form-item>
		
				<el-form-item label="ที่อยู่" prop="address">
					<el-input v-model="addressForm.address" placeholder="ค้นหาตําแหน่งเซลล์、ถนน、ออฟฟิศ"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">ยกเลิก</el-button>
				<el-button type="primary" @click="addmemberAddress('form')">เพิ่มข้อมูล</el-button>
			</span>
		</el-dialog>

		<!--ใช้ยอดเงินคงเหลือ-->
		<div class="item-block" v-if="orderPaymentData.member_account.balance_total > 0 && balance_show == 1">
			<div class="block-text">ใช้ยอดเงินคงเหลือหรือไม่</div>

			<div class="pay-type-list">
				<div class="pay-type-item" :class="orderCreateData.is_balance ? '' : 'active'" @click="useBalance(0)">ไม่ใช้ยอดเงินคงเหลือ</div>
				<div class="pay-type-item" :class="orderCreateData.is_balance ? 'active' : ''" @click="useBalance(1)">ใช้ยอดเงินคงเหลือ</div>
				<div class="clear"></div>
			</div>
		</div>


		<!-- วิธีการจัดส่ง -->
		<div class="item-block padd-bom-20" v-if="orderPaymentData.is_virtual == 0">
			<div class="pay-type-list">วิธีการจัดส่ง
				<span class="distribution" v-if="orderPaymentData.delivery_type == 'store' && orderCreateData.delivery.store_name">{{ orderCreateData.delivery.store_name }}</span>
			</div>
			<div v-if="orderPaymentData.shop_goods_list.express_type.length > 0">
				<div class="pay-type-item" v-for="(deliveryItem, deliveryIndex) in orderPaymentData.shop_goods_list.express_type"
				 :key="deliveryIndex" @click="selectDeliveryType(deliveryItem)" :class="orderPaymentData.delivery_type == deliveryItem.name ? 'active' : ''"
				 v-if="deliveryItem.name != 'local'">
					{{ deliveryItem.title }}
				</div>
			</div>
			<div v-else-if="memberAddress.length == 0">
				<div class="box ns-text-color">โปรดเพิ่มที่อยู่จัดส่งก่อน</div>
			</div>
			<div v-else>
				<div class="box ns-text-color">ผู้ขายไม่ได้กําหนดค่าวิธีการจัดส่ง</div>
			</div>

		</div>

		<!--ข้อมูลผลิตภัณฑ์-->
		<div class="item-block">
			<div class="goods-list">
				<table>
					<tr>
						<td width="70%">สินค้า</td>
						<td width="15%">ราคา</td>
						<td width="15%">ปริมาณ</td>
					</tr>
				</table>
			</div>
		</div>

		<div>
			<div class="item-block">
				<div class="goods-list">
					<table>
						<tr v-for="(goodsItem, goodsIndex) in orderPaymentData.shop_goods_list.goods_list" :key="goodsIndex">
							<td width="70%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<img class="goods-img" :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(goodsIndex)" />
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + goodsItem.sku_id }" target="_blank">
											<div class="goods-name">{{ goodsItem.sku_name }}</div>
										</router-link>
										<!-- สเปค -->
										<div class="goods-spec" v-if="goodsItem.sku_spec_format">
											<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">{{ x.spec_value_name }} {{ i < goodsItem.sku_spec_format.length - 1 ? '; ' : '' }}</span>
										</div>
									</div>
								</div>
							</td>
							<td width="15%" class="goods-price">฿{{ goodsItem.price }}</td>
							<td width="15%" class="goods-num">{{ goodsItem.num }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>




		<!--สิทธิพิเศษ-->
		<div class="item-block">
			<div class="block-text">กิจกรรมร้านค้า</div>
			<div class="order-cell platform-coupon">
				<span class="ns-text-color">ซื้อยกแพ็ค {{ orderPaymentData.groupbuy_info.buy_num }} ชิ้น ราคาเพียง {{ orderPaymentData.groupbuy_info.groupbuy_price }} บาท</span>
			</div>
		</div>

		<!-- ข้อความจากผู้ซื้อ -->
		<div class="item-block padd-bom-10">
			<div class="block-text">ข้อความจากผู้ซื้อ</div>
			<el-input rows="3" type="textarea" placeholder="ขอแนะนําให้ประสานงานกับผู้ขายก่อนฝากข้อความ" v-model="orderCreateData.buyer_message" class="buyer-message"
			 @input="textarea" maxlength="140" show-word-limit resize="none"></el-input>
		</div>

		<!-- ทั้งหมด -->
		<div class="item-block">
			<div class="order-statistics">
				<table>
					<tr>
						<td align="right">มูลค่ารวมสินค้า：</td>
						<td align="left">฿{{ orderPaymentData.goods_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.is_virtual == 0">
						<td align="right">ค่าขนส่ง：</td>
						<td align="left">฿{{ orderPaymentData.delivery_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.invoice_money > 0">
						<td align="right">ภาษี：</td>
						<td align="left">฿{{ orderPaymentData.invoice_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.promotion_money > 0">
						<td align="right">สิทธิพิเศษ：</td>
						<td align="left">฿{{ orderPaymentData.promotion_money | moneyFormat }}</td>
					</tr>
					<tr v-if="orderPaymentData.balance_money > 0">
						<td align="right">ใช้ยอดเงินคงเหลือ：</td>
						<td align="left">฿{{ orderPaymentData.balance_money | moneyFormat }}</td>
					</tr>
				</table>
			</div>
			<div class="clear"></div>
		</div>

		<!--นิคม-->
		<div class="item-block">
			<div class="order-submit">
				<div class="order-money">
					ทั้งหมด {{ orderPaymentData.goods_num }} รายการ จํานวนเงินที่ต้องชําระ：
					<div class="ns-text-color">฿{{ orderPaymentData.pay_money | moneyFormat }}</div>
				</div>
				<el-button type="primary" class="el-button--primary" @click="orderCreate" style="       font-weight: 500;
				font-family: 'Prompt', sans-serif !important; ">การชําระคําสั่งซื้อ</el-button>
			</div>
			<div class="clear"></div>
		</div>

		<!--วิธีการจัดส่ง-->
		<el-dialog title="เลือกร้านค้า" :visible.sync="dialogStore" width="50%">
			<el-table ref="singleTable" :data="storeList" highlight-current-row @row-click="selectStore" class="cursor-pointer">
				<el-table-column label="" width="55">
					<template slot-scope="scope">
						<el-radio v-model="storeRadio" :label="scope.row"><i></i></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="store_name" label="ชื่อ" width="160"></el-table-column>
				<el-table-column prop="store_address" label="ที่อยู่"></el-table-column>
				<el-table-column prop="open_date" label="เวลาทําการ"></el-table-column>
			</el-table>
		</el-dialog>

		<!-- วิธีการจัดส่ง ซื้อกลับบ้าน -->
		<el-dialog title="เวลาจัดส่ง" :visible.sync="deliveryTime" width="400px">
			<el-form status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="เวลาจัดส่ง">
					<el-col :span="11">
						<el-time-picker format="HH:mm" value-format="HH:mm" placeholder="เลือกเวลา" :value="time" v-model="time" start="09:01"
						 end="21:01" @change="bindTimeChange"></el-time-picker>
					</el-col>
				</el-form-item>
				<el-form-item label="">
					<el-col :span="20">
						<div class="align-right">
							<el-button size="small" @click="deliveryTime = false">ปิดเครื่อง</el-button>
							<el-button type="primary" size="small" @click="setDeliveryTime(siteDelivery.site_id)">ยืนยันการเลือกของคุณ</el-button>
						</div>
					</el-col>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- รหัสผ่านการชําระเงิน -->
		<el-dialog title="ใช้ยอดเงินคงเหลือ" :visible.sync="dialogpay" width="350px">
			<template v-if="orderPaymentData.member_account.is_pay_password == 0">
				<p>เพื่อความปลอดภัยของบัญชีของคุณ,โปรดตั้งรหัสผ่านการชําระเงินก่อน</p>
				<p>มีอยู่"ศูนย์สมาชิก","ความปลอดภัยของบัญชี","รหัสผ่านการชําระเงิน"การตั้งค่า</p>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="dialogpay = false">ยังไม่ได้ตั้งค่า</el-button>
					<el-button size="small" type="primary" @click="setPayPassword">ตั้งค่าเดี๋ยวนี้</el-button>
				</span>
			</template>
			<el-form v-else status-icon ref="ruleForm" label-width="100px">
				<el-form-item label="รหัสผ่านการชําระเงิน" class="pay-password-item">
					<!--เพิ่มสิ่งที่มองไม่เห็นinput,การป้อนอัตโนมัติของเบราว์เซอร์ปลอมแปลง-->
					<el-input type="password" class="pay-password hide-password" :maxlength="6"></el-input>
					<el-input type="password" class="pay-password" :maxlength="6" v-model="password" @input="input"></el-input>
				</el-form-item>
				<p class="ns-text-color forget-password" @click="setPayPassword">ลืมรหัสผ่าน</p>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import detail from './payment_groupbuy.js';
	export default {
		name: 'groupbuy_payment',
		components: {
			PicZoom
		},
		mixins: [detail]
	};
</script>

<style lang="scss" scoped>
	@import './payment_groupbuy.scss';
	.item-block {
		    padding: 0 15px 1px;
		    margin: 0px 0;
		    border-radius: 0;
		    border: none;
		    background: #ffffff;
		}.item-block .block-text {
	    border-color: #eeeeee;
	    color: #303133;
	    padding: 7px 0;
	    font-size: 17px;
	    font-weight: 500;
	    border-bottom: 1px;
	}.pay-type-list {
	   font-size: 17px;
	   font-weight: 500;
	}.pay-type-item.active {
	   font-weight: 500;
	   font-size: 15px;
	}.pay-type-item {
	   font-weight: 400;
	   font-size: 15px;
	}
</style>
